import { APP_INITIALIZER, NgModule, } from '@angular/core';
import { CommonModule, HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { WeaverService } from './wf/service/weaverservice';
import { DeliveryOrderService } from './wf/service/deliveryorderservice';
import { NgxPrintModule } from 'ngx-print';
import { SizerService } from './wf/service/sizerservice';
import { DesignDetailService } from './wf/service/designdetailsservice';
import { BarCodesService } from './wf/service/fetchBarCodesService';
import { UserService } from './wf/service/userservice';
import { HTTP_INTERCEPTORS, 
    HttpClientModule,
	provideHttpClient,
	withInterceptors } from '@angular/common/http';
import { AuthInterceptor } from './wf/util/request.interceptor';
import { ResponseInterceptor } from './wf/util/response.interceptor';
import { LoadingInterceptor } from './wf/util/loading.interceptor';
import { SpinnerComponent } from './layout/spinner/spinner.component';
import { zip } from 'rxjs';
import { DescopeAuthModule, DescopeAuthService, descopeInterceptor } from '@descope/angular-sdk';
import { environment } from 'src/environments/environment';

export function initializeApp(authService: DescopeAuthService) {
	return () => zip([authService.refreshSession(), authService.refreshUser()]);
}

@NgModule({
    declarations: [AppComponent, SpinnerComponent],
    imports: [AppRoutingModule, AppLayoutModule, NgxPrintModule, CommonModule,
        DescopeAuthModule.forRoot({
            projectId: environment.projectId
          })
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [DescopeAuthService],
            multi: true
        },
        provideHttpClient(withInterceptors([descopeInterceptor])),
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        {provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true},
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
        WeaverService, DeliveryOrderService, SizerService,
        DesignDetailService, BarCodesService, UserService
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
